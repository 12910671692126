.body {
  padding-top: 20px;
}

.filterRow {
  margin-left: 1px;
}

.filterComp {
  margin-left: 4px;
  margin-right: 4px;
}
.order-history-tabs {
  border-right: none;
}
.ant-menu.order-history-tabs .ant-menu-item-selected {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.65);
}
.order-history-tabs.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected::after {
  border: none;
}
.order-history-tabs.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:hover::after {
  border: none;
}
.order-history-tabs.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border: none;
}
.ant-menu.order-history-tabs .ant-menu-item:hover {
  color: #000000de;
}
.ant-menu.order-history-tabs .ant-menu-item.active {
  background-color: transparent;
  color: #31b057;
}

.ant-menu.order-history-tabs .ant-menu-item.active:hover {
  color: #31b057;
  border-color: #31b057;
}

.ant-menu.order-history-tabs
  .ant-menu-item
  > span.ant-menu-title-content::after {
  display: none;
  text-align: center;
}

.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 5px solid #00942c;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: 5px solid #e6e6e6;
}
.filterText {
  margin-top: 8px;
  margin-left: 2px;
  margin-right: 2px;
}

.header {
  color: #000;
  width: 95%;
  display: inline-block;
}

.header :hover {
  cursor: pointer !important;
}

.ant-calendar-range-picker-separator {
  position: relative;
  top: 4px;
}

.ant-calendar-range-picker-input {
  width: 37%;
}

.filter-text {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  font-size: 16px;
  color: #313331;
}

.filter-wrapper {
  padding: 16px 0px 0px 0px;
  background-color: #fff;
  margin: 0px;
}

.input-search-wrapper .ant-input {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d0ced9;
  border-radius: 22px;
}
.input-search .ant-input-search-button,
.input-search .ant-input-group-addon {
  border-radius: 0 20px 20px 0 !important;
}

.input-search-wrapper {
  text-align: right;
  bottom: -15px;
  margin-left: 4%;
}

.order-history-table {
  display: table;
  margin: 0px;
  padding: 0px;
  width: 100%;
  border-collapse: collapse;
}

.order-history-table-header-row,
.order-history-table-body-row {
  display: table-row;
}

.order-history-table-body-row {
  height: 55px;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
}

.order-history-table-row-date {
  /* display: table-row; */
  background: #e2e2e2 0% 0% no-repeat padding-box;
}

.order-history-table-header-row > span {
  list-style: none;
  display: table-cell;
  padding: 11px;
  font-size: 13px;
}

.order-history-table-body-row span {
  list-style: none;
  display: table-cell;
  padding: 0px 11px;
}

.order-history-table-header {
  display: table-header-group;
}

.order-history-table-header span {
  background: #c7e2d9 0% 0% no-repeat padding-box;
  height: 36px;
  font-size: 13px;
  color: #00000099;
}

.order-history-table-body {
  display: table-row-group;
}

.view-order-btn {
  margin-top: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #1a8d5f;
  border-radius: 4px;
  color: #1a8d5f;
  border-radius: 5px;
  height: 35px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.order-date {
  background: #f9fafc 0% 0% no-repeat padding-box;
  margin: 0;
  font-size: 11px;
  padding: 5px 16px;
}

.order-history-table .ant-table-placeholder {
  display: none;
}

.order-history-table .ant-table-thead > tr > th {
  font-size: 13px;
  color: #00000099;
  font-weight: bold;
  background: #f3f7fa;
  padding: 12px 16px;
}

.order-history-table .ant-table-tbody > tr > td {
  font-size: 13px;
}

.order-history-header {
  padding: 0 40px;
}

.order-history-body {
  padding: 0 20px 0 40px;
}

.empty-text {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #e8e8e8;
  padding: 16px;
  font-size: 14px;
  text-align: center;
}

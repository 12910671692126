@import '../../../styles/colors/index.css';

.if-title {
  font-size: 13px;
}

/* .if-input-container {
  width: 100%;
} */

.if-input {
  /* width: 50%; */
  /* height: 40px; */
  margin-top: 6px;
  color: var(--Light-Text);
}

.if-input::before {
  display: none;
}

.if-input > .ant-input {
  padding-left: 8px !important;
}

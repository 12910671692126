.profile-wrapper {
  margin-top: 20px;
}

.sub-row {
  padding: 10px 0;
}

.bank-modal .ant-modal-body {
  padding: 6px !important;
}

.btn-wrapper {
  padding-left: 16px;
  padding-top: 16px;
}

.bank-modal .ant-table-thead > tr > th {
  color: gray;
  font-size: 13px;
}

.bank-modal .ant-table-tbody > tr > td {
  color: black;
  font-size: 12px;
}

.bank-table-wrapper {
  padding: 0 15px;
}

.bank-table-text {
  font-weight: bold;
  padding: 10px 0;
}

.profile-card {
  width: 712px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  font-family: Roboto;
}

.no-profile-image {
  height: 75px;
  width: 75px;
  border: 1px dashed #b3b3b3;
  background: #ddf1ec 0% 0% no-repeat padding-box;
  font-size: 11px;
  text-align: center;
  color: #00946e;
  font-weight: 500;
  line-height: 70px;
  cursor: pointer;
}

.no-profile-image span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.profile-image {
  height: 70px;
  width: 70px;
  border: 1px solid #b3b3b3;
  cursor: pointer;
}

.profile-image-text {
  font-size: 11px;
  padding-top: 10px;
  padding-left: 0;
}

.ticket {
  font-size: 12px;
  font-family: 'Times New Roman';
}

.ticket th {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-collapse: collapse;
}

.ticket td.description,
.ticket th.description {
  width: 75px;
  max-width: 75px;
}

.ticket td.quantity,
.ticket th.quantity {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

.ticket td.price,
.ticket th.price {
  width: 60px;
  max-width: 60px;
  word-break: break-all;
}

.centered {
  text-align: center;
  align-content: center;
}

.ticket {
  width: 210px;
  max-width: 210px;
}

.ticket img {
  max-width: inherit;
  width: inherit;
}

.ticket .left {
  margin-top: 10px;
}

.item-price-wrapper {
  margin-top: 10px;
}

.item-price-wrapper .label {
  width: 115px;
}

@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ticket {
    width: 220px;
    max-width: 220px;
  }

  .ticket td.description,
  .ticket th.description {
    width: 100px;
    max-width: 100px;
  }

  .ticket td.price,
  .ticket th.price {
    width: 90px;
    max-width: 90px;
    word-break: break-all;
  }

  .item-price-wrapper .label {
    width: 140px;
  }

  body {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
}

.btnRow {
  margin-left: 1px;
  margin-top: 30px;
  margin-bottom: -20px;
}

.file-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 100%;
}

.reject-modal .ant-modal-body {
  padding: 0 !important;
}

.reject-modal .ant-radio-wrapper {
  font-weight: 500;
  color: #333333;
  padding: 20px 0;
  width: 100%;
}

.reject-modal .ant-divider-horizontal {
  height: 2px;
  margin: 0;
}

.reject-modal .ant-modal-title {
  font-weight: bold;
}

.reject-modal
  .ant-radio-group
  .ant-radio-wrapper:hover
  .ant-radio
  .ant-radio-inner,
.reject-modal
  .ant-radio-group
  .ant-radio-wrapper
  .ant-radio:hover
  .ant-radio-inner,
.reject-modal .ant-radio-input:focus + .ant-radio-inner,
.reject-modal
  .ant-radio-group
  .ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #006cb4;
}

.reject-modal
  .ant-radio-group
  .ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: #006cb4;
}

.reject-modal .ant-btn:disabled {
  color: #00000099;
  background: #c2c2c2;
  opacity: 1;
}

.img-info {
  font-size: 13px;
  color: #313331;
}

.auto-complete-search-wrapper {
  margin-bottom: 10px;
}

.auto-complete-search-wrapper .ant-select-selection {
  background-color: transparent;
}

.auto-complete-search-wrapper .ant-select-auto-complete.ant-select .ant-input {
  background: #ffffff;
  border-radius: 22px;
}

.auto-complete-search-wrapper .ant-select-selection-search-input {
  border-radius: 22px;
}

.auto-complete-search-wrapper
  .ant-select-auto-complete.ant-select
  .ant-input:focus {
  border-color: #1a8d5f !important;
  box-shadow: 0 0 0 2px #d1e8df !important;
}
.network-error-notification .ant-notification-notice-message,
.network-error-notification .ant-notification-notice-close {
  color: rgb(255, 255, 255, 0.8);
}

.network-error-notification .ant-notification-notice-close:hover {
  color: rgb(255, 255, 255, 1);
}

@media (max-width: 1200px) {
  .ant-select-show-search.ant-select-auto-complete {
    width: 250px !important;
  }
}

@media (max-width: 1440px) {
  .ant-select-show-search.ant-select-auto-complete {
    width: 300px !important;
  }
}

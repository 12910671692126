.onboarding-header {
  height: 37vh;
  background: url('../../assets/images/top_banner.png') no-repeat center;
}

.ant-card {
  margin-bottom: 0;
}

.input-label {
  font-size: 11px;
  color: #666666;
}

.verified {
  background-color: #77b7a61f;
}

.disabled {
  background-color: #eee;
}

/* .onboarding-container .ant-btn-primary {
  background-color: #045cae !important;
} */

.onboarding-container .ant-card {
  box-shadow: 1px 1px 5px #ccc;
}

.onboarding-container .ant-card h5 {
  font-size: 16px;
  color: #434343;
}

.onboard-text {
  font-size: 15px;
  color: #666666;
}

.acct-mismatch-text {
  font-size: 11px;
  color: red;
  padding-top: 5px;
}
.menu-block {
  font-size: 11px;
  text-align: center;
}
.add-admin-text {
  font-size: 14px;
  margin-top: 10px;
}
.onboarding-welcome-msg {
  font-size: 21px;
  font-weight: lighter;
}
.custom-tooltip .ant-tooltip-inner {
  background-color: #fff;
}

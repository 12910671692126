.rowStoreInfoInstantSearch {
  display: flex;
}

.colStoreInfo {
  width: 20%;
  padding: 20px;
  font-size: 21px;
  font-weight: bold;
}

.colInstantSearch {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  flex: 1;
}
.colCreateStoreButton {
  display: flex;
  align-items: center;
}

.centerAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.colCreateStoreButton button {
  margin: 0;
}

.width85percent {
  width: 85%;
}
.width45percent {
  width: 45%;
}
.width15percent {
  width: 15%;
}
.width300px {
  width: 300px;
}

.borderWidth1Box {
  border: 1px solid grey;
  border-radius: 4px;
  padding: 0 30px;
}
.marginTop0 {
  margin-top: 0 !important;
}
.marginBottom0 {
  margin-bottom: 0 !important;
}
.alignSelfCenter {
  align-self: center;
}

.storesFormContainer {
  font-family: 'Roboto', sans-serif;
  justify-content: space-between;
  display: flex;
  margin-bottom: 30px;
}
.storeFormTitle {
  display: flex;
  /* justify-content: center; */
  font-size: 22px;
  color: #333333;
  margin: 20px 0 0 0;
}
.storeFlexWrapper {
  display: flex;
  justify-content: space-between;
}
.storeInputWrapper {
  margin: 20px 0;
  position: relative;
}

.storeSwitchWrapper {
  margin: 20px 20px 20px 0;
  position: relative;
}

.alignCenter {
  align-items: center;
  display: flex;
  justify-content: center;
}

.OrderX-Main .ant-switch .ant-switch-checked .isStorePosEnabled {
  background-color: #b2b2b2 !important;
}

.OrderX-Main .ant-switch-checked {
  background-color: #1a8d5f !important;
}

.OrderX-Main .ant-switch {
  background-color: #e04f1a;
}
.ant-radio-button-wrapper:hover {
  color: #1a8d5f;
  background-color: #e8f3ef;
}
.OrderX-Main .ant-switch.grey-green-switch {
  background-color: #999;
}

.entityFieldTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.nohoverTableWrapper
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row)
  > td {
  background: unset !important;
}

.storeMapWrapper {
  width: 40%;
  height: 700px;
}

.header {
  color: #00000099;
}

.header:hover {
  cursor: pointer !important;
  color: #000000de !important;
}

.divCenter {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}

.header {
  color: #000;
}

.header :hover {
  cursor: pointer !important;
}

.light-yellow-bg-color {
  background-color: #fff;
}

.light-red-bg-color {
  background-color: #fff;
}

.ant-modal-header {
  border: 0 !important;
}

.ant-modal-footer {
  padding: 24px !important;
  border: 0 !important;
}

.order-popup .ant-modal-body {
  padding: 0;
}

.popup-text-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 15px 15px;
  padding: 10px 20px;
}

.popup-header-text {
  text-align: left;
  font: 15px Roboto;
  letter-spacing: 0px;
  color: #666666;
  font-weight: bold;
}

.popup-sub-text {
  text-align: left;
  font: 12px Roboto;
  color: #999999;
  font-weight: bold;
}

.place-order-text {
  font: 14px Roboto;
  letter-spacing: 0px;
  color: #006cb4;
  font-weight: bold;
}

.view-order-modal .ant-modal-body {
  padding-top: 0;
}
.view-order-modal .ant-table-scroll div.ant-table-body {
  overflow-y: auto !important;
}

.view-order-modal .ant-modal-header .title-label {
  font-size: 16px;
  font-weight: bold;
}

.view-order-modal .ant-table-thead > tr > th,
.view-order-modal .ant-table-tbody > tr > td {
  padding-top: 5px;
  padding-bottom: 5px;
}

.view-order-modal .ant-table-thead > tr > th {
  background: #e2e2e2;
}

.view-order-modal .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0;
}

.view-order-modal .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0;
}

.view-order-modal .section-header {
  padding: 5px 5px 5px 10px;
  background: #e2e2e2;
  font-weight: 500;
  margin: 10px 0;
}

.view-order-modal .ant-collapse-item {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}

.view-order-modal .ant-collapse-header {
  background: #e2e2e2 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px;
  font-weight: 500 !important;
}

.white-tooltip .ant-tooltip-arrow::before {
  background-color: #fff;
  width: 10px;
  height: 10px;
  border: 1px solid #ddd;
}

.white-tooltip .ant-tooltip-inner {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #333;
}

.order-search-input .ant-input {
  border-radius: 20px;
}
.order-search-input .ant-input-search-button,
.order-search-input .ant-input-group-addon {
  border-radius: 0 20px 20px 0 !important;
}

.rider-busy-text {
  margin-top: 20px;
  background: #cfe6d6 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 4px 0px;
  color: #0a8543;
  font: normal 12px Roboto;
  padding: 4px 0 4px 27px;
}

.self-pickup-container {
  margin-top: 20px;
  border-radius: 4px 4px 4px 0px;
  padding: 4px 0 4px 5px;
  display: flex;
  align-items: center;
}

.peppo-rider-symbol {
  width: 0px;
  height: 0px;
  border-top: 0px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 21px solid #289006;
  position: absolute;
  left: 16px;
  top: 21px;
}

.peppo-rider-text-p {
  position: absolute;
  right: 12px;
  color: white;
  font-size: 9px;
  padding-top: 1px;
}

.grey-green-radio .ant-radio-button-wrapper {
  background-color: #efefef;
  font-weight: 450;
  font-size: 13px;
}

.grey-green-radio .ant-radio-button-wrapper:hover {
  color: #289006;
  background-color: #28900624;
}

.grey-green-radio
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #289006;
  border-color: #289006;
  background-color: #28900624;
  box-shadow: -1px 0 0 0 #289006;
}

.navbar-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 20px;
}
.toggle-button-button {
  margin-left: 5%;
  padding: 8px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  width: 195px;
}
.help-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.current-store-status-text {
  font-size: 11px;
}
.circle {
  font-size: 12px;
  font-weight: 'normal';
}
.status-block {
  width: 26%;
  display: inline-block;
  text-align: right;
}
.top-spacing {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10;
}
.vertical-spacing {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10;
  margin-bottom: 20;
}
.ant-tooltip-disabled-compatible-wrapper {
  width: 65%;
}

.h1 {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
  height: 29px;
  color: #1a1a1a;
}

.h2 {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
  height: 22px;
  color: #1a1a1a;
}

.h3 {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
  height: 22px;
  color: #808080;
}

.h4 {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
  height: 17px;
  color: #1a1a1a;
}

.h5 {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
  height: 17px;
  color: #808080;
}

.h6 {
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  opacity: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0;
  height: 15px;
  color: #b3b3b3;
}

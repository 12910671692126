.sideMenuText {
  font-size: 15px;
  margin-left: 0 !important;
}

.material {
  font-size: 20px;
  margin-right: 19px;
  margin-bottom: -3px;
}

.orderX-Logo {
  margin-left: -18px;
  display: flex;
  align-items: center;
}

.peppo-bms-logo {
  width: 250px;
}
.OrderX-Main {
  min-height: 100%;
  background-color: #f9fafc !important;
  overflow: inherit;
}

.sidebar-collapsed .ant-menu .ant-menu-item {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  overflow: visible;
}

.menu-collapsed {
  width: 48px !important;
}

.OrderX-Sidebar .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 5px;
}

.OrderX-Logo {
  background-color: #fcfcfc !important;
}

.OrderX-Logo .gx-layout-sider-header {
  background-color: #fcfcfc !important;
}

.OrderX-Sidebar .ant-menu-dark {
  background-color: #0c0b0b !important;
}

.OrderX-Topbar {
  background-color: #ffffff !important;
}

.OrderX-Sidebar .ant-menu-dark .ant-menu-item img {
  opacity: 0.6;
}

.OrderX-Sidebar .custom-badge {
  position: absolute;
  right: -10px;
  z-index: 12;
}

.set-badge-admin .custom-badge {
  top: 96px;
}

.set-badge .custom-badge {
  top: 12px;
}

.sideMenuText .ant-badge {
  margin-left: 10px;
}

.OrderX-Sidebar .ant-menu-item a {
  display: flex;
  align-items: center;
}

.OrderX-Sidebar .ant-menu-dark .ant-menu-item-selected img {
  opacity: 1;
}

.OrderX-Sidebar .ant-menu-dark .ant-menu-item-selected {
  background-color: #00825f !important;
}

.OrderX-Sidebar .ant-menu-dark .ant-menu-item-selected > a {
  /* color: #62ff77 */
  box-sizing: border-box;
  color: #fff;
  opacity: 1;
}

.OrderX-Sidebar .ant-menu-dark .ant-menu-item-selected > svg {
  color: #fff;
}

.OrderX-Sidebar .ant-menu-dark .ant-menu-item:hover {
  background-color: #303030;
}

.peppo-white-logo {
  margin-bottom: 25px;
}

.peppo-white-logo img {
  width: 100px;
}

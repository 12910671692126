.ant-card.menu-card {
  margin-bottom: 10px;
  cursor: pointer;
}

.ant-card.menu-card:hover,
.ant-card.menu-card:focus {
  border-color: #d8d8d8;
}

.ant-card.menu-card .ant-card-body {
  padding: 15px;
}

.catalog-delete-icon {
  padding-top: 11px;
  cursor: pointer;
  font-size: 20px;
}

.catalog-delete-icon:hover {
  color: #ff0000;
}

.ant-btn.ant-btn-primary.btn-primary {
  background-color: #00942c !important;
  border-color: #00942c !important;
  margin-bottom: 0 !important;
}

.customize-header-container {
  padding: 15px 15px 0 25px;
  border-bottom: 1px solid lightgray;
  background-color: #ffffff;
}

.customize-body-container {
  margin-left: 23px;
}

.btn-container {
  text-align: right;
  padding: 0 5px;
}

.customize-menu {
  border-right: none;
}
.ant-menu.customize-menu .ant-menu-item-selected {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.65);
}
.ant-menu.customize-menu .ant-menu-item:hover {
  color: #000000de;
}
.ant-menu.customize-menu .ant-menu-item.active {
  background-color: transparent;
  color: #31b057;
}

.ant-menu.customize-menu .ant-menu-item.active:hover {
  color: #31b057;
  border-color: #31b057;
}

.ant-menu.customize-menu .ant-menu-item > span.ant-menu-title-content::after {
  display: none;
}
.view-menu .ant-table {
  background: #fff;
}

.view-menu .product-table .ant-table-body {
  overflow-y: auto !important;
}

.view-menu .product-table .ant-table-header {
  overflow: auto !important;
}

.product-table .ant-input-number {
  width: 65px;
}

.view-menu .ant-table-thead > tr > th {
  background: #fff;
  border-bottom: 0;
}

.selected-item {
  background-color: #f3f7fa;
  color: #31b057;
}

.selected-item .ant-list-item {
  color: #31b057;
}
.searched-item {
  background: #f3f7fa;
}

.totalDiv {
  text-align: right;
}

.rightIcon {
  cursor: pointer;
  display: none;
}

.rightIcon:hover {
  display: block;
  cursor: pointer;
}

.view-menu .ant-table-tbody > tr:hover {
  background-color: #f3f7fa;
}

.category-table-body-wrapper {
  height: 70vh;
  overflow: auto;
}

.selected-item .rightIcon,
.view-menu .ant-table-tbody > tr:hover .rightIcon,
.category-table-body:hover .rightIcon {
  display: block;
  cursor: pointer;
}

.editCatalog {
  font-size: 20px;
  padding: 0 10px;
  cursor: pointer;
  display: inline-block;
}

.catalogName {
  font: Bold 24px Roboto;
  color: #333333;
}

.unverified-catalog-name {
  font: Bold 24px Roboto;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  display: inline-block;
}

.catalogNameInput {
  width: 60%;
  font-size: 16px;
}

.hideCol {
  display: none;
}

.showCol {
  display: block;
}

.list-wrapper {
  background: white;
  padding: 10px 5px;
  margin-bottom: 20px;
}

.list-wrapper .ant-list-items .ant-list-item,
.list-wrapper .ant-list-items .ant-row .rightIcon {
  border-bottom: 0;
  padding: 12px 10px;
  cursor: pointer;
}

.add-new-btn,
.add-new-btn:hover,
.add-new-btn:focus {
  background: #ddf1ec;
  border-radius: 4px;
  width: 100%;
  font-size: 13px;
  color: #01946f;
  font-weight: bold;
  border: transparent;
}

.list-wrapper .ant-list-items .dragIcon .ant-row:hover {
  color: #000000de;
}

.list-wrapper .ant-list-items {
  height: 68vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-wrapper .ant-list-items .ant-row:hover .rightIcon {
  display: block;
  cursor: pointer;
}

.form-wrapper {
  background: white;
  padding: 20px;
  margin-left: 20px;
}

.isListable {
  padding: 20px 0;
}

.categoryImage {
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.applyBtn {
  background-color: #00c8ba;
  color: #fff;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 5px;
  width: 120px;
}
.applyBtn:hover,
.applyBtn:focus,
.applyBtn.ant-btn-loading {
  background-color: #00c8ba;
  color: #fff;
  border: 1px solid #00c8ba;
}

.applyBtn[disabled],
.applyBtn[disabled]:hover {
  color: #00000087;
  background-color: #c1baba;
}

.imgTxt {
  color: #01946f;
  font-weight: bold;
}

.isListable .ant-switch-checked {
  background-color: #00942c !important;
}

.isListable .ant-switch {
  background-color: #ff4747;
}

.order-wrapper {
  background-color: #f2f2f2;
  margin: 0 0 15px 0;
  padding: 7px 0;
}

.apply-text {
  color: #00946e;
  font-weight: bold;
  cursor: pointer;
}

.apply-text:hover {
  color: #00946e;
}

.order-btn {
  font-size: 20px;
  margin: 0 2px;
  color: #9b9ba4;
  cursor: pointer;
}

.detailImage,
.noImage {
  border-radius: 4px;
  width: 99px;
  height: 99px;
  border: 1px solid #00946e;
  cursor: pointer;
  background-size: contain;
}
.noImage {
  border: 1px dashed #00946e;
  background: #ddf1ec 0% 0% no-repeat padding-box;
  font-size: 12px;
  text-align: center;
  color: #00946e;
  font-weight: 500;
  line-height: 90px;
}

.noImage span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.detailImage:hover {
  cursor: pointer;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.ant-radio-group .ant-radio-wrapper.veg {
  color: #00942c;
}

.ant-radio-group .ant-radio-wrapper.non-veg {
  color: #ff4747;
}

.ant-radio-group .ant-radio-wrapper.veg:hover .ant-radio .ant-radio-inner,
.ant-radio-group .ant-radio-wrapper.veg .ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-group .ant-radio-wrapper.veg .ant-radio-checked .ant-radio-inner {
  border-color: #00942c;
}

.ant-radio-group
  .ant-radio-wrapper.veg
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: #00942c;
}

.ant-radio-group .ant-radio-wrapper.non-veg:hover .ant-radio .ant-radio-inner,
.ant-radio-group .ant-radio-wrapper.non-veg .ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-group
  .ant-radio-wrapper.non-veg
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #ff4747;
}

.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 5px solid #00942c;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: 5px solid #e6e6e6;
}

.ant-radio-group
  .ant-radio-wrapper.non-veg
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: #ff4747;
}

.not-verified-icon {
  color: red;
  font-size: 18px;
  padding-left: 4px;
}

.not-verified-text {
  color: red;
  font-size: 13px;
}

.verify-warning-text {
  background: #fbd2d3;
  border: 1px solid #df9999;
  padding: 5px;
  font-size: 12px;
}

.grey-container {
  padding: 12px;
  background-color: #f2f2f2;
}

.delete-icon {
  font-size: 19px;
  padding: 13px 0 0 0;
}

.charge-amount-wrapper {
  margin: 10px 0 35px 0;
}

.charge-amount-container {
  background: white;
  padding: 8px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin: 0;
}

.pd-0 {
  padding: 0 !important;
}

.view-menu .ant-table-thead > tr > th {
  color: #999999;
}

.action-text {
  color: #00942c;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
}

.custom-modal .ant-modal-header {
  padding-bottom: 0;
}

.custom-modal .ant-col.pd-left {
  padding-left: 1px;
}

.custom-modal .ant-col.pd-right {
  padding-right: 1px;
}

.backIcon {
  font-size: 18px;
  color: #999999;
  padding-right: 20px;
  padding-top: 7px;
}

.imageText {
  padding-top: 8px;
}

.imageText .ant-typography:first-child {
  margin-top: 3px;
}

.no-pd-left {
  padding-left: 0;
}

.no-pd-right {
  padding-right: 0;
}

.menu-card .anticon-right {
  color: #00942c;
}

.delete-icon .anticon-delete:hover {
  color: #ff4747;
}

.menu-help {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 59px;
  height: 24px;
  padding: 5px 15px;
  border: 1px solid #e6e6e6;
  text-decoration: none;
  color: #00000066;
}
.menu-help:hover {
  border: 1px solid #00000066;
}

.view-menu
  .ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}

.view-menu .header:hover {
  cursor: pointer !important;
  color: #8b8686 !important;
}

.view-menu .header {
  width: 100% !important;
}

.view-menu {
  width: 99%;
  margin: auto;
  position: relative;
}

/* .category-table .ant-table-tbody > tr > td:first-child,
.category-table .ant-table-tbody > tr > td:last-child,
.product-table .ant-table-tbody > tr > td:first-child {
  padding: 0;
} */

.category-table .ant-table-thead > tr > th:first-child,
.category-table .ant-table-tbody > tr > td:first-child {
  width: 60%;
}

.product-table .ant-table-tbody > tr > td:first-child div {
  cursor: pointer;
}

.category-table .ant-table-tbody > tr > td:nth-child(2),
.category-table .ant-table-thead > tr > th:nth-child(2) {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.product-table .ant-table-tbody > tr > td {
  border-bottom: none;
}

.product-table .ant-table-tbody > tr {
  cursor: pointer;
}

/* .category-table .ant-table-tbody > tr > td:first-child div,
.category-table .ant-table-tbody > tr > td:last-child div,
.product-table .ant-table-tbody > tr > td:first-child div {
  padding: 16px;
} */

.category-list-wrapper {
  background: #fff;
  border-right: 1px solid #e6e6e6;
}

.category-table-header,
.category-table-body {
  padding: 16px 0 16px 16px;
  margin-right: auto;
  align-items: center;
}

.unavailable-item-row,
.unavailable-item-header {
  padding: 12px 0px;
}

.text-align-center {
  text-align: center;
}

.category-table-body:hover {
  background-color: #f3f7fa;
  cursor: pointer;
}

.unavailable-item-body-wrapper {
  max-height: 30vh;
  overflow: auto;
  overflow-x: hidden;
}

.unavailable-items-modal .ant-switch-checked {
  background-color: #00942c !important;
}

.unavailable-items-modal .ant-switch {
  background-color: #ff4747;
}

.unavailable-items-modal .ant-modal-body {
  padding-top: 0;
}

.no-item-table {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
}

.taxes-heading {
  font-size: 15px;
  color: #333333;
  font-weight: 500;
}

.taxes-sub-heading {
  font-size: 13px;
  color: #313331;
}

.confirm-tab-modal {
  width: 352px !important;
  height: 128px;
}

.confirm-tab-modal .ant-modal-header {
  padding: 12px !important;
}

.confirm-tab-modal .ant-modal-body {
  padding: 0 12px !important;
}

.unavailable-items-modal .ant-modal-footer {
  text-align: left;
  border-top: 1px solid #eee !important;
}

.tax-drawer .ant-drawer-content-wrapper {
  border-top: 0.5px solid rgba(0, 0, 0, 0.15);
  width: 320px !important;
}

.tax-drawer .ant-drawer-close {
  width: 15px !important;
}

.charge-wrapper {
  margin-bottom: 24px;
}
.charge-field {
  margin: 0;
  margin-bottom: 8px;
}
.price-popover {
  border-radius: 8px;
  width: 330px;
  z-index: 1 !important;
  top: 22px !important;
}

.price-popover .ant-popover-content .ant-popover-arrow {
  display: none;
}

.price-popover .ant-popover-content .ant-popover-inner {
  border-radius: 8px;
}
.price-popover
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding: 8px !important;
}

.percentage-input {
  overflow: hidden;
  display: flex;
  flex: 1;
  height: 40px;
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  margin: 0 12px;
}

.control-wrapper {
  height: 40px;
  width: 40px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.number-input {
  height: 40px;
  flex: 1;
  border: none;
  text-align: center;
}

@media (max-width: 1440px) {
  .ant-select-show-search.ant-select-auto-complete.certain-category-search {
    width: 100% !important;
  }
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  width: 125.5px;
}

.price-change-form .ant-form-item-control-wrapper {
  width: 100%;
}
.ant-input-number-lg {
  width: 100%;
}
.sortableHelper {
  z-index: 99999999 !important;
  background-color: white;
}
.dragIcon {
  margin: 0px 10px;
}

.drag-icon {
  width: 10px;
}

.drag-visible {
  cursor: grab;
}

.category-table-row td {
  border-bottom: none !important;
}

.category-name {
  cursor: pointer;
}
.category-toggle .ant-switch-checked {
  background-color: #1a8d5f !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
}

.row-dragging .category-name {
  width: 200px;
}

.row-dragging td {
  padding: 16px;
}
